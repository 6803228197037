<template>
  <base-section
    id="easy-to-use"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="700"
    >
      <base-info-title
        align="center"
        dark
        v-bind="getTitle"
      />
    </v-responsive>

    <v-responsive
      class="mx-auto"
    >
      <v-container>
        <v-row justify="space-around">
          <v-col
            v-for="card in getCards"
            :key="card.title"
            cols="12"
            sm="3"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>

  </base-section>
</template>

<script>
  export default {
    name: 'SectionEasyToUse',

    computed: {
      getTitle () {
        return [
          {
            title: this.$t('useTitle'),
            text: this.$t('useSubTitle'),
          },
        ]
      },

      getCards () {
        return [
          {
            title: this.$t('useReason1Title'),
            subtitle: 'STEP ONE',
            text: this.$t('useReason1Content'),
          },
          {
            title: this.$t('useReason2Title'),
            subtitle: 'STEP TWO',
            text: this.$t('useReason2Content'),
          },
          {
            title: this.$t('useReason3Title'),
            subtitle: 'STEP THREE',
            text: this.$t('useReason3Content'),
          },
        ]
      },
    },
  }
</script>
